import React, { useState } from "react"
import { graphql } from "gatsby"
import { Box, Grid, Text, Heading, Divider, IconButton } from "theme-ui"
import { GatsbyImage } from "jam-cms"
import Parser from "html-react-parser"

import { ChevronUp } from "mdi-material-ui"

// import app components
import Edges from "../../components/Edges"
import Button from "../../components/Button"
import Layout from "../../components/Layout"
import Logo from "../../icons/pwww_corner.svg"
import Textarea from "../../components/Textarea"

const DefaultProductTemplate = (props) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const {
    data: {
      wpProduct: {
        seo,
        acf: { section1, section2, section3, section4, section5 },
      },
    },
  } = props

  return (
    <Layout {...props} seo={seo}>
      {/*
      Section 1 -----------------------------------------------------------------------------------------------
      */}
      <Box
        bg={"black"}
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "100%",
        }}
      >
        <Grid
          sx={{
            position: "relative",
            height: "100%",
            gridRow: 1,
            gap: 0,
            bg: "black",
            width: "100%",
          }}
          columns={[1, "50% 50%", "50% 50%"]}
        >
          <Box
            sx={{
              position: "relative",
              bg: "black",
              gridRowStart: 1,
              gridColumnStart: 1,
              width: "100%",
              display: "flex",
              height: ["300px", "400px", "450px"],
            }}
          >
            {section1?.headline && (
              <Heading
                as="h1"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  ml: [4, 5, 6],
                  mb: 5,
                  letterSpacing: "1px",
                  fontWeight: 500,
                  fontSize: [10, 11, 13],
                  maxWidth: "430px",
                }}
                color="white"
              >
                {Parser(section1?.headline)}
              </Heading>
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              gridRowStart: [2, 1],
              gridColumnStart: [1, 2],
              width: "100%",
              height: ["300px", "400px", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image1 && (
              <GatsbyImage
                image={section1?.image1}
                alt={section1?.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              bg: "white",
              gridRowStart: [4, 2],
              gridColumnStart: [1, 2],
              width: "100%",
              height: "100%",
              minHeight: ["300px", "600px"],
              pt: ["30px", "90px"],
              pl: ["30px", "45px"],
              pr: ["30px", "105px"],
              pb: ["30px", "90px"],
            }}
          >
            {section1?.text && <Textarea content={section1?.text} />}
          </Box>
          <Box
            sx={{
              position: "relative",
              gridRowStart: [3, 2],
              gridColumnStart: 1,
              pt: ["5px", 0],
              width: "100%",
              height: ["300px", "400px", "450px"],
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "400px", "450px"],
              },
            }}
          >
            {section1?.image2 && (
              <GatsbyImage
                image={section1?.image2}
                alt={section1?.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Grid>
      </Box>
      {/*
      Section 2-----------------------------------------------------------------------------------------------
      */}
      <Box bg={"black"} pb={["45px", "90px"]}>
        <Grid
          sx={{ gap: ["5px", ".4%"] }}
          columns={[1, "9.6% 39.6% 40.4% 10.4%"]}
        >
          <Box
            sx={{
              gridColumnStart: [1, 2],
              gridRowStart: 1,
              width: "100%",
              height: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: "100%",
              },
            }}
          >
            {section2?.image1 && (
              <GatsbyImage
                image={section2?.image1}
                alt={section2?.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridColumnStart: [1, 3],
              gridRowStart: [2, 1],
              width: "100%",
              height: "100%",
              ".gatsby-image-wrapper-constrained": {
                width: "100%",
                height: "100%",
              },
            }}
          >
            {section2?.image2 && (
              <GatsbyImage
                image={section2?.image2}
                alt={section2?.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Grid>
      </Box>
      {/*
      Section 3-----------------------------------------------------------------------------------------------
      */}
      {section3?.text && section3?.accordion && section3?.accordion.length > 0 && (
        <Grid
          bg={"bgDark"}
          sx={{ position: "relative", gap: "0", gridRow: 1, height: "auto" }}
          columns={1}
        >
          <Box
            bg={"bgDark"}
            sx={{
              gridRowStart: 1,
              gridRowEnd: 2,
              gridColumnStart: 1,
              display: "flex",
              width: "100%",
              height: "100%",
              pb: ["60px", "100px"],
            }}
          >
            <Edges size="sx">
              <Box
                sx={{
                  color: "white",
                  display: "flex",
                  pt: ["30px", "50px"],
                  pb: "40px",
                  position: "relative",
                  zIndex: 2,
                }}
              >
                {section3?.text && (
                  <Heading
                    variant="text.accordionHead"
                    children={section3?.text}
                  />
                )}
              </Box>

              {section3?.accordion &&
                section3?.accordion.map((o, i) => {
                  return (
                    <Box
                      sx={{ zIndex: 2, position: "relative" }}
                      key={i}
                      onClick={() => {
                        setActiveIndex(i === activeIndex ? null : i)
                      }}
                    >
                      {i === 0 ? (
                        <Divider color="white" my={[3, 3, "1.5em"]} />
                      ) : null}
                      {o.title && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            cursor: "pointer",
                            alignItems: "center",
                            position: "relative",
                            zIndex: 2,
                          }}
                        >
                          <Heading
                            variant="styles.accordionTitle"
                            children={Parser(`${o.title}`)}
                            sx={{
                              position: "relative",
                              zIndex: 2,
                            }}
                          />
                          <IconButton
                            sx={{
                              ml: "auto",
                              alignSelf: "center",
                              svg: {
                                height: ["100%", "100%", "130%"],
                                width: ["100%", "100%", "130%"],
                                color: "white",
                                position: "relative",
                                zIndex: 2,
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              },
                            }}
                          >
                            <ChevronUp
                              style={{
                                transform:
                                  activeIndex === i
                                    ? "rotate(0)"
                                    : "rotate(180deg)",
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                      {o.text && (
                        <Box
                          color="white"
                          sx={{
                            fontSize: [0, 0, 0, 1, 2],
                            mt: [3, 3, 4],
                            display: activeIndex === i ? "block" : "none",
                            "div > *": {
                              color: "white !important",
                            },
                          }}
                        >
                          <Textarea content={o.text} />
                        </Box>
                      )}
                      <Divider color="white" my={[3, 3, "1.5em"]} />
                    </Box>
                  )
                })}
            </Edges>
          </Box>

          <Box
            bg={"bgDark"}
            mt={"auto"}
            mr={"auto"}
            color="white"
            sx={{
              opacity: 0.1,
              gridRowStart: [2, 1],
              gridColumnStart: 1,
              gridRowEnd: 2,
              width: ["200px", "400px"],
              position: "relative",
              zIndex: 1,
              mb: "-5px",
              svg: {
                width: ["60%", "60%", "100%"],
                height: ["60%", "60%", "100%"],
              },
            }}
          >
            <Logo />
          </Box>
        </Grid>
      )}
      {/*
        Section 4-----------------------------------------------------------------------------------------------
        */}
      <Box bg={"black"} pt={["40px", "80px"]} pb={["20px", "40px"]}>
        <Edges size="sx">
          <Grid column={[1, "50% 50%"]}>
            <Box
              sx={{
                gridColumnStart: 1,
                gridRowStart: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                mt: "20px",
              }}
            >
              {section4?.headline && (
                <Heading
                  variant="styles.sec4Pheading"
                  sx={{
                    pb: "24px",
                    width: [null, null, "400px"],
                  }}
                  children={section4?.headline}
                />
              )}
              {section4?.text && (
                <Text
                  color="white"
                  sx={{
                    fontWeight: 400,
                    fontSize: [2, 3],
                    pb: ["24px", 0],
                    width: [null, null, "400px"],
                    pr: [null, null, "50px"],
                  }}
                  children={section4?.text}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridColumnStart: [1, 2],
                gridRowStart: [2, 1],
              }}
            >
              {section4?.link?.url && (
                <Box
                  sx={{
                    mx: "auto",
                  }}
                >
                  <Button to={section4?.link.url}>
                    {section4?.link.title}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Edges>
      </Box>
      {/*
      Section 5-----------------------------------------------------------------------------------------------
      */}
      <Box
        bg={"black"}
        sx={{
          pb: ["20px", "40px"],
        }}
      >
        <Box
          sx={{
            display: ["flex", "grid"],
            flexDirection: "column",
            gridTemplateColumns: [1, "1fr 1fr 1fr 1fr 1fr 1fr"],
            gridTemplateRows: [null, "1fr 1fr 1fr 1fr"],
            gap: [0, "5px"],
          }}
        >
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 4,
              gridColumnStart: 1,
              gridColumnEnd: 4,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image1 && (
              <GatsbyImage
                image={section5.image1}
                alt={section5.image1?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image2 && (
              <GatsbyImage
                image={section5.image2}
                alt={section5.image2?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 4,
              gridColumnEnd: 6,
              width: "100%",
              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image3 && (
              <GatsbyImage
                image={section5.image3}
                alt={section5.image3?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>

          <Box
            sx={{
              gridRowStart: 3,
              gridRowEnd: 5,
              gridColumnStart: 6,
              gridColumnEnd: 7,
              width: "100%",

              ".gatsby-image-wrapper": {
                width: "100%",
                height: ["300px", "100%"],
              },
            }}
          >
            {section5.image4 && (
              <GatsbyImage
                image={section5.image4}
                alt={section5.image4?.altText}
                objectPosition="0"
                objectFit="cover"
                className="gatsbyImage"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query ProductDefault($id: String!) {
    wpProduct(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      acf {
        fieldGroupName
        section1 {
          fieldGroupName
          headline
          text
          image1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
        }
        section2 {
          fieldGroupName
          image1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
        }
        section3 {
          fieldGroupName
          text
          accordion {
            fieldGroupName
            text
            title
          }
        }
        section4 {
          fieldGroupName
          text
          headline
          link {
            target
            title
            url
          }
        }
        section5 {
          fieldGroupName
          image1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 750, quality: 100)
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 100)
              }
            }
          }
          image3 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 100)
              }
            }
          }
          image4 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default DefaultProductTemplate
